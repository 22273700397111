import FloatPoint from "../entities/floats/FloatPoint.js";
import TimeUtils from "./TimeUtils.js";

export const KeyCode = Object.freeze({
  "A": "KeyA",
  "B": "KeyB",
  "D": "KeyD",
  "E": "KeyE",
  "I": "KeyI",
  "K": "KeyK",
  "S": "KeyS",
  "W": "KeyW",
  "Z": "KeyZ",
  "Shift": "ShiftLeft",
  "Enter": "Enter",
  "Esc": "Escape",
  "Up": "ArrowUp",
  "Down": "ArrowDown",
  "Ctrl": "ControlLeft",
  // Meta is a key on Mac keyboards, which is used in place of the Windows key on Windows keyboards.
  // To make sure that pressed key is "Meta" in different browsers and OS only we have to check the "key" property of
    // KeyboardEvent object. The "code" property may be different.
  "Meta": "Meta"
});

/**
 * To be used when generating HTML via string interpolation e.g. {@code safeHtml`<div>${variables}</div>`} -
 * these variables will go through this function and have to be escaped should such string be shown on UI.
 *
 * @param {string[]} strings
 * @param {string} values
 * @returns {string}
 */
export const safeHtml = (strings, ...values) => {
  let result = strings[0];
  for (let i = 0; i < values.length; i++) {
    result += safeText(String(values[i]));
    result += strings[i + 1];
  }
  return result;
}

/**
 * @param {string} unsafe
 * @returns {string}
 */
export const safeText = (unsafe) => {
  if (!unsafe)
    return ''
  return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
}

/**
 * 0 => A, 1 => B, 2 => C, ..., 25 => Z, 26 => AA, 27 => AB, ...
 * @param {number} number
 * @returns {string}
 */
export const convertIntToLetters = (number) => {
  if (typeof number !== "number")
    throw new Error(`Passed value [${number}] is not a number`);
  if (number < 26)
    return String.fromCharCode(65 + number);
  return convertIntToLetters(number / 26 - 1) + convertIntToLetters(number % 26);
}

/**
 * A => 0, B => 1, C => 2, ..., Z => 25, AA => 26, AB => 27, ...
 * @param {string} string
 * @returns {number}
 */
export const convertLettersToInt = (string) => {
  let result = 0;
  for (let i = 0; i < string.length; i++) {
    const current = string.charCodeAt(i) - 65;
    if (i < string.length - 1)
      result += (current + 1) * Math.pow(26, string.length - i - 1);
    else
      result += current;
  }
  return result;
}

export const convertJsonToBase64 = (json) => {
    return btoa(JSON.stringify(json));
}

export const floatPointsFromBase64String = (str) => {
  const d = new DataView(Int8Array.from(atob(str), c => c.charCodeAt(0)).buffer)
  const points = [];
  for (let i = 0; i <= d.byteLength-8; i += 8) {
    points.push(new FloatPoint(d.getInt32(i), d.getFloat32(i+4)))
  }
  return points;
}


/**
 * @param {string} one
 * @param {string} another
 * @returns {number} - A negative number if one occurs before another;
 *          positive if the one occurs after another;
 *          0 if they are equivalent.
 * */
export const compareStringsByCodePoints = (one, another) => {
  if (typeof one !== "string" || typeof another !== "string")
    throw new Error(`Both comparable must be strings. One: {type: ${typeof one}, value: ${one},
     another: {type: ${typeof another}, value: ${another}`);
  if (one.length !== another.length)
    return one.length - another.length;
  for (let i = 0; i < one.length; i++) {
    let cp1 = one.codePointAt(i), cp2 = another.codePointAt(i);
    if (cp1 !== cp2)
      return cp1 - cp2;
  }
  return 0;
}

export const getPageScrollPercentage = () => {
  const scrollHeight = document.documentElement.scrollHeight;
  const scrolled = Math.ceil(document.documentElement.scrollTop + document.documentElement.clientHeight);
  return (scrolled / scrollHeight) * 100;
}
export function extractHighlightedWellsLiteralCoordinatesFromUrl() {
  const plates = [];
  const platesParams = extractHashParamFromUrl('#highlight');
  if (!platesParams) return [];
  const regex = RegExp('(\\[[A-Za-z0-9:,;]{0,}\\])','g');
  let result;
  while ((result = regex.exec(platesParams)) !== null) {
    const cells = result[0].replace(new RegExp('[\\[\\]]', 'g'),'').split(';');
    plates.push(cells)
  }
  return plates;
}

/*
  Executes callback function with delay. Another function call with same timerId before delay end will reset the timer.
 */
export function doWithDelay(timerId, fun, delay) {
  if (timerId) clearTimeout(timerId);
  return setTimeout(fun, delay || 700);
}

export function extractHashParamFromUrl(param) {return new URLSearchParams(window.location.hash).get(param);}
export function urlHasParam(param) {
  return new URLSearchParams(window.location.search).has(param);
}
function noop() {/*do nothing*/}

export function randomId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export const arrayEquals = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

/**
 * Converts number using scientific notation and rounds to 2 fraction digits
 * Examples:
 *  0.1 => 1E-1
 *  0.001019 => 1.01E-3
 *  1000.1 => 1E+3
 *  1250.345 => 1.25E+3
 * @param val
 * @return {string}
 */
export const nicelyRounded = (val) => {
  if (typeof val !== "number")
    throw new Error(`Passed value [${val}] is not a number`)
  if (val === 0)
    return '0'
  if (-0.01 < val && val < 0.01)
    return val.toExponential(2).replace('e', 'E');
  let a = val.toPrecision(4);
  if (!a.includes('e'))
    return ''+Number.parseFloat(a);
  let arr = a.split('e');
  return Number.parseFloat(arr[0]) + 'E'+arr[1];
}

export function formatMoney(currency, amount) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount);
}

export function formattedDateFromMillis(millis) {
    const d = new Date(0);
    d.setUTCMilliseconds(millis);
    return d.toLocaleString('en-Gb',
        {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'});
}

/**
 * @deprecated When injection meta json will contain date in ISO 8601 format,
 * move logic to {@link formatDateTimeForDisplayingInTableFromISO}
 *
 * Returns only time, if it is today, otherwise returns only date
 * @param {number} millis
 * @returns {string}
 */
export function formatDateTimeForDisplayingInTable(millis) {
  const date = new Date(millis);
  const isToday = TimeUtils.isToday(new Date(millis));
  return isToday
      ? date.toLocaleTimeString('en-GB')
      : formatDateForDisplayingInTable(date);
}
/**
 * Always returns a string which represents a date in the format: 'dd MM YYYY'
 * @param {Date} date
 * @returns {string}
 */
export function formatDateForDisplayingInTable(date) {
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
}

/**
 * Returns only time, if it is today, otherwise returns only date
 * @param {string} isoDate - ISO 8601 date string (e.g., "2025-01-08T10:14:08.563229Z")
 * @returns {string}
 */
export function formatDateTimeForDisplayingInTableFromISO(isoDate) {
  formatDateTimeForDisplayingInTable(new Date(isoDate).getTime())
}



/**
 * If necessary, rounds a number to no more than the specified decimal place
 * https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
 */
export function round(num, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces);
  const n = (num * p) * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

export class HTMLSanitizer {
  static sanitizeSelectorTemplate = (template, ...args) => {
    let result = template[0];
    for (let i = 0; i < args.length; i++)
      result += HTMLSanitizer.escapeQuotes(args[i]) + template[i + 1];
    return result;
  }

  static escapeQuotes = (string) => {
    return String(string).replace(/['"\\]/g, '\\$&')
  }
}

/**
 * The function makes the string lowercase and replaces all non-alphanumeric characters (except for hyphens and
 * underscores) with hyphens.
 *
 * @param {string} name
 * @return {string}
 */
export function urlFriendlyName(name){
  return name.toLowerCase().replace(/[^a-z0-9_-]/g, "-")
}

/**
 * We faced some issues with the browser cache when the user clicked the back button. This function disables the
 * browser cache for the current page.
 * TODO: Do not forget to remove this function after the issue is resolved.
 */
export function disableBFCache() {
  window.addEventListener('pageshow', function (event) {
    if (event.persisted) {
      // Enable BF cache for now, let's see if it causes a lot of problems:
      // window.location.reload();
    }
  });
}

/**
 * Function add possibility to use $...$ for displaying in-line mathematics
 * Because the $...$ are not supported by default
 * https://docs.mathjax.org/en/latest/basic/mathematics.html
 */
export function configInlineMathInMathJax(){
  MathJax.config.tex.inlineMath = [['\\(', '\\)'], ['$', '$']];       // inline
  MathJax.startup.getComponents();
}

/** We checked is Ctrl/Cmd + "A" pressed */
export function isSelectAllPressed(evt){
  return (evt.code === KeyCode.A && !evt.repeat && (evt.ctrlKey || evt.metaKey))
}

// Is needed for different hints, when we want to personalize keys information, for example, "Press Ctrl/Cmd + Enter ..."
export function ctrlOrCmdBtn(){
  return (getOS() === OS.MAC) ? "Cmd" : "Ctrl";
}

export const OS = Object.freeze({
  MAC: "Mac",
  WIN: "Windows",
  LINUX: "Linux"
});

/**
 * Function that determines what operating system the user has
 */
export function getOS(){
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf('Windows') !== -1) {
    return OS.WIN
  } else if (userAgent.indexOf('Mac') !== -1) {
    return OS.MAC
  } else if (userAgent.indexOf('Linux') !== -1) {
    return OS.LINUX
  } else {
    throw new Error("Unsupported operating system. Please contact the administrator.")
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function scrollToHash(){
  const hash = window.location.hash;
  if (hash) {
    const targetElement = document.querySelector(hash);
    if (targetElement) {
      targetElement.scrollIntoView();
    }
  }
}

/**
 * Use this function to scroll the page to a specific element.
 * In a test environment, scrollIntoView is not available, causing the test to fail.
 *
 * @param {HTMLElement} el
 */
export function safeScrollToElement(el){
  if (typeof el.scrollIntoView === "function"){
    el.scrollIntoView()
  }
}