import HtmlUtils from "../../components/HtmlUtils.js";
import ErrorPageIoC from "./ErrorPageIoC.js";
import SecurityContext from "../../js/entities/SecurityContext.js";
import UserWithOrgs from "../../js/entities/user/UserWithOrgs.js";

export default class ErrorPageCmp {
    /** @type {ErrorPageHeaderCmp} */
    #pageHeader;
    /** @type {ErrorPageIoC} */
    #ioc;
    /** @type {SecurityContext} */
    #securityContext;
    /** @type {HTMLElement} */
    #rootEl;

    constructor() {
        this.#createSecurityContext();
        this.#ioc = new ErrorPageIoC(this.#securityContext)
        this.#rootEl = document.querySelector('#error-page');
        this.#pageHeader = this.#ioc.getPageHeaderCmp();
        this.#pageHeader.init(document.querySelector('.page-header'));
        this.#pageHeader.setSecurityContext(this.#securityContext);
    }

    showForbidden() {
        if (this.#securityContext.isAuthenticated()) {
            this.#rootEl.innerHTML = HtmlUtils.accessDeniedHtmlTemplate(this.#securityContext.mainPageUrl, "Main page")
        } else {
            this.#rootEl.innerHTML = HtmlUtils.accessDeniedHtmlTemplate(this.#securityContext.signInUrl, "Sign in")
        }
    }

    showNotFound() {
        this.#rootEl.innerHTML = HtmlUtils.resourceNotFoundHtmlTemplate(this.#securityContext.mainPageUrl, "Main page")
    }

    /**
     * For testing
     * @returns {SecurityContext}
     */
    getSecurityContext(){
        return this.#securityContext;
    }

    #createSecurityContext() {
        const signInUrl = document.querySelector('#sign-in-url').value;
        const userJson = JSON.parse(document.querySelector('#user').value);
        const user = userJson.anonymous ? null : UserWithOrgs.parseServerJson(userJson);
        this.#securityContext = new SecurityContext(user, '', signInUrl);
    }
}