import GlobalErrorHandler from "../../js/entities/error/GlobalErrorHandler.js";
import HttpErrorHandler from "../../js/entities/error/HttpErrorHandler.js";
import {disableBFCache} from "../../js/util/CommonUtils.js";
import DialogUtils from "../../components/DialogUtils.js";
import ErrorPageCmp from "./ErrorPageCmp.js";

window.onload = async () => {
    disableBFCache();
    DialogUtils.initMutationObserverToPreventScrollWhenDialogIsOpened();
    const globalErrorHandler = new GlobalErrorHandler();
    globalErrorHandler.init();
    globalErrorHandler.addHttpErrorHandler(new HttpErrorHandler());
    const errorPageCmp = new ErrorPageCmp();
    await errorPageCmp.showNotFound();
}