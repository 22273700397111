import {initHeaderScrollShadow} from "../../../js/util/HeaderUtils.js";

export default class ErrorPageHeaderHtml {
    #root;

    /** @param {HTMLElement} headerEl */
    init(headerEl) {
        this.#root = headerEl;
        initHeaderScrollShadow(headerEl);
    }

    /** @returns {HTMLElement} */
    getUserHeaderElement() {
        return this.#root.querySelector('#user-section');
    }
}