import HttpClient from "../../js/repository/HttpClient.js";
import UserHeaderCmp from "../../components/page-header/UserHeaderCmp.js";
import OrgRepository from "../../js/repository/OrgRepository.js";
import ErrorPageHeaderCmp from "./ErrorPageHeader/ErrorPageHeaderCmp.js";

export default class ErrorPageIoC {
	/** @type {Map<string, any>} */
	#cached = new Map();
	/** @type {SecurityContext} */
	#securityContext;

	/**
	 * @param {SecurityContext} securityContext
	 * @see {HttpClient#createSecurityContext}
	 */
	constructor(securityContext) {
		if (!securityContext)
			throw new Error('Security context is required');
		this.#securityContext = securityContext;
		/** @type {Map<string, any>} */
		this.#cached = new Map();
	}

	/** @returns {ErrorPageHeaderCmp} */
	getPageHeaderCmp() {
		return this.#cache('errorPageHeaderCmp', () => new ErrorPageHeaderCmp(this.getUserHeaderCmp()));
	}

	/** @returns {UserHeaderCmp} */
	getUserHeaderCmp(){
		return this.#cache("userHeaderCmp", ()=> new UserHeaderCmp(this.getOrganizationRepository()))
	}

	/** @returns {OrgRepository} */
	getOrganizationRepository() {
		return this.#cache('organizationRepo', () => new OrgRepository(this.getHttpClient()));
	}

	/** @returns {HttpClient} */
	getHttpClient() {
		return this.#cache('httpClient', () => new HttpClient('', this.#securityContext));
	}

	/**
	 * @returns {any}
	 * @private
	 */
	#cache(cmpName, createCmpFn) {
		let cmp = this.#cached.get(cmpName);
		if (!cmp) {
			cmp = createCmpFn();
			this.#cached.set(cmpName, cmp);
		}
		return cmp;
	}
}