export default class HtmlUtils {
    static getParentSvgSize(parentElement) {
        const parentSvg = parentElement.closest('svg');
        if(!parentSvg)
            throw new Error(parentElement + ' was not SVG and did not have SVG as one of its parents');
        return HtmlUtils.getBoundingRect(parentSvg);
    }
    static getBoundingRect(htmlElement) {
        const clientRect = htmlElement.getBoundingClientRect();
        if(!this._isPositiveNum(clientRect.width) || !this._isPositiveNum(clientRect.height))
            // throw new Error(this.getHtmlElementPath(htmlElement) + ' has no width or height: ' + JSON.stringify(clientRect));
            console.warn(this.getHtmlElementPath(htmlElement) + ' has no width or height: ' + JSON.stringify(clientRect));
        return clientRect;
    }

    /**
     * @param {Element} htmlElement
     */
    static getHtmlElementPath(htmlElement) {
        const path = [];
        let element = htmlElement;
        while(element && element.tagName) {// apparently <html> has a parent, but it doesn't have a tag name
            let classes = element.classList.value ? '.' + element.classList.value : '';
            path.push(element.tagName + classes);
            element = element.parentNode;
        }
        return path.reverse().join(' > ');
    }
    static _isPositiveNum(num) {
        return typeof num === 'number' && num > 0;
    }

    /**
     * @param {HTMLElement} pageContentElement
     * @param {HttpError} httpError
     */
    static onPageDataAccessExceptionThrown(pageContentElement, httpError) {
        const {securityContext, isForbidden, isUnauthorized, isNotFound}
            = httpError;
        if (isUnauthorized || isForbidden) {
            let actionUrl = securityContext.mainPageUrl;
            let actionName = 'Main page';
            if (!securityContext.isAuthenticated()) {
                actionUrl = securityContext.signInUrl;
                actionName = 'Sign In';
            }
            pageContentElement.innerHTML = HtmlUtils.accessDeniedHtmlTemplate(actionUrl, actionName);
        } else if (isNotFound) {
            pageContentElement.innerHTML = HtmlUtils.resourceNotFoundHtmlTemplate(securityContext.mainPageUrl, 'Main page');
        }
    }

    /**
     * @param {string} actionUrl
     * @param {string} actionName
     * @returns {string}
     */
    static accessDeniedHtmlTemplate(actionUrl, actionName) {
        return `
            <div class="resource-not-found-message-container">
                <span class="resource-not-found-message-container__text js-text">You don't have the required permissions to access this page.</span>
                <a class="js-sign-in-link" href="${actionUrl}">${actionName}</a>
            </div>`;
    }
    /**
     * @param {string} actionUrl
     * @param {string} actionName
     * @returns {string}
     */
    static resourceNotFoundHtmlTemplate(actionUrl, actionName) {
        return `
            <div class="resource-not-found-message-container">
                <span class="resource-not-found-message-container__text js-text">The page doesn't exist.</span>
                <a class="js-action-link" href="${actionUrl}">${actionName}</a>
            </div>`;
    }

    /**
     * Only for backward compatibility, until all usages are replaced with new HTML5Dialog's
     * This fixes a bug, when something is present in the DOM tree after the modal element.
     * Since we have css rule that shows only the last in the dom modal, they are not visible.
     * @deprecated
     * @param {HTMLElement} el
     */
    static createModal(el){
            const modal = document.createElement('div');
            modal.classList.add('modal');
            modal.setAttribute('tabIndex', '0')
            modal.append(el);
            let container = document.querySelector('.modal-container');
            if (!container) {
                container = document.createElement('div');
                container.classList.add('modal-container');
                document.body.append(container);
            }
            container.append(modal);
            return modal;
    }
}